import confetti from 'canvas-confetti';

export default {
  methods: {
    giveMeConfetti(duration = 3000) {
      const end = Date.now() + duration;
      const interval = setInterval(() => {
        if (Date.now() > end) {
          return clearInterval(interval);
        }

        confetti({
          zIndex: 1001,
          startVelocity: 30,
          spread: 360,
          ticks: 120,
          colors: ['#ffcd60', '#ffe7a5', '#fff1c7', '#ffad28', '#00bbd3', '#1860af'],
          shapes: ['circle', 'square'],
          origin: {
            x: Math.random(),
            y: Math.random() - 0.2,
          },
        });
      }, 200);
    },
  },
};