<template>
  <div class="workoutVideo">
    <base-heading type="2">
      {{ workout.name }}
    </base-heading>

    <div class="workoutVideo__wrapper">
      <VideoPlayer
        ref="videoplayer"
        v-bind:src="sources"
        v-bind:placeholder="workout.image"
        v-bind:tracks="workout.tracks"
        v-bind:title="workout.name"
        v-bind:track-program="workout.program ? workout.program.theme : null"
        v-bind:track-title="workout.name"
        v-bind:track-period="workout.period ? workout.period.number : null"
        v-bind:track-intensity="trackIntensity"
        v-bind:track-id="workout.id"
        v-bind:track-length="workout.duration"
        v-bind:playback-position="workout.video_position"
        track-goal="Workout"
        autoplay
        v-on:casting="casting"
        v-on:play="onPlay"
        v-on:time="onProgress"
        v-on:complete="onComplete"
        v-on:setupError="onVideoError"
        v-on:error="onVideoError"
      />

      <p
        v-if="castingActive"
        class="workout-video-casting"
      >
        <font-awesome-icon v-bind:icon="['fal', 'exclamation-triangle']" />

        {{ $t('workoutModalVideo.castingNote') }}
      </p>
    </div>

    <div class="workoutVideo__buttons">
      <base-button
        modifiers="block skipWorkout sm"
        class="workoutVideo__button skip-workout"
        v-bind:disabled="!videoError && !hasStarted"
        v-bind:loading-text="$t('workoutModalVideo.skippingButtonLabel')"
        v-bind:show-spinner="skipConfirmed"
        v-on:click="onSkipWorkoutClick"
      >
        {{ skip ? $t('workoutModalVideo.skippingButtonLabel') : $t('workoutModalVideo.skipButtonLabel') }}
      </base-button>

      <base-button
        modifiers="block skipWorkout sm"
        class="workoutVideo__button"
        v-on:click="showReportTool = true"
      >
        De video werkt niet
      </base-button>
    </div>

    <workout-video-report-tool
      v-if="showReportTool"
      v-bind:workout="workout"
      v-bind:video-streams="sources"
      v-bind:video-error="videoError"
      v-on:close="showReportTool = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import VideoPlayer from '@/components/VideoPlayer';
import WorkoutVideoReportTool from '@/components/workout/WorkoutVideoReportTool';
import { isIOS, isSafari } from '@/utils/device';

export default {
  components: {
    VideoPlayer,
    BaseHeading,
    BaseButton,
    WorkoutVideoReportTool,
  },

  props: {
    workout: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasStarted: false,
      skip: false,
      skipConfirmed: false,
      castingActive: false,
      readyToSendTime: false,
      videoError: null,
      showReportTool: false,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod']),

    sources() {
      const streams = this.workout.streams.filter(({ file }) => !!file);

      if (streams.length > 1 && (isIOS() || isSafari())) {
        const hlsIndex = streams.findIndex(({ type }) => type === 'hls');

        if (hlsIndex > 0) {
          const hlsStream = streams[hlsIndex];

          streams.splice(hlsIndex, 1);
          streams.splice(0, 0, hlsStream);
        }
      }

      return streams;
    },

    duration() {
      if (this.workout.duration) {
        return new Date(this.workout.duration * 1000)
          .toISOString()
          .substr(14, 5);
      }

      return null;
    },

    trackIntensity() {
      const { intensities } = this.currentPeriod;
      const { active_intensity } = this.currentProgram;
      const intensity = intensities.find(({ intensity }) => intensity === active_intensity);

      return intensity ? intensity.name : '-';
    },
  },

  methods: {
    ...mapActions({
      startWorkout: 'workout/start',
      playingWorkout: 'workout/progress',
      skipWorkout: 'workout/skip',
      finishWorkout: 'workout/complete',
    }),

    onSkipWorkoutClick() {
      if (this.skip) {
        this.onSkip();
      } else {
        this.skip = true;
      }
    },

    casting(event) {
      this.castingActive = event;
    },

    onPlay() {
      if (this.hasStarted) {
        return;
      }

      const startWorkoutWrapper = new Promise((resolve) => {
        if (this.workout.status === 'in_progress') {
          resolve();
          return;
        }

        this.startWorkout(this.workout.id)
          .then(resolve)
          .catch(() => {});
      });

      startWorkoutWrapper.finally(() => {
        this.hasStarted = true;
        this.readyToSendTime = false;
      });
    },

    onSkip() {
      this.pause();
      this.skipConfirmed = true;

      this.skipWorkout({ workoutId: this.workout.id, periodId: this.currentPeriod.id })
        .then(() => this.$emit(this.workout.can_rate ? 'nextPage' : 'close'))
        .catch(() => this.$emit('close'))
        .finally(() => this.skipConfirmed = false);
    },

    onProgress({ currentTime }) {
      // send an event every ten seconds
      const roundedTime = Math.round(currentTime);
      const tenSecondTime = roundedTime.toString().slice(-1);

      if (tenSecondTime < 1) {
        // don't call api immediately. onProgress() fires more than once a second so just set it to ready and fire it in the next second.
        this.readyToSendTime = true;
      }

      if (tenSecondTime > 1 && this.readyToSendTime) {
        this.readyToSendTime = false;

        this.playingWorkout({
          workoutId: this.workout.id,
          position: Math.floor(currentTime),
        });
      }
    },

    onComplete() {
      this.finishWorkout({ workoutId: this.workout.id, periodId: this.currentPeriod.id })
        .then(() => this.$emit('nextPage'))
        .catch(() => this.$emit('close'));
    },

    pause() {
      this.$refs.videoplayer.pause();
    },

    onVideoError(error) {
      this.videoError = error;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutVideo__buttons {
  margin: rem(16px) 0;
  text-align: center;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.workoutVideo__button {
  margin: 0 0 rem(16px) 0;

  &:last-child {
    margin: 0;
  }

  @include desktop {
    flex: 0 0 auto;
    margin: 0 rem(16px) 0 0;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
