<template>
  <div class="formElement">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.formElement {
  margin: 0 0 rem(16px) 0;

  &:last-child {
    margin: 0;
  }
}
</style>