<template>
  <div class="skeletonLoaderCard">
    <skeleton-loader
      v-bind:rounded="true"
      v-bind:height="imageHeight"
      class="skeletonLoaderCard__image"
    />

    <skeleton-loader
      v-if="showTitle"
      class="skeletonLoaderCard__title"
    />
  </div>
</template>

<script>
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  components: {
    SkeletonLoader,
  },

  props: {
    imageHeight: {
      type: String,
      default: '160px',
    },

    showTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.skeletonLoaderCard__image {
  margin: 0 0 rem(12px) 0;
}
</style>