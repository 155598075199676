<template>
  <div
    v-bind:class="{
      'ratingButton': true,
      [`ratingButton--rate${rating.value}`]: true,
    }"
    v-on:click="$emit('update:modelValue', rating)"
  >
    <rating-icon
      class="ratingButton__icon"
      v-bind:rating="rating.value"
    />

    <p class="ratingButton__label">
      {{ rating.name }}
    </p>

    <div class="ratingButton__hover" />
  </div>
</template>

<script>
import RatingIcon from '@/components/RatingIcon';

export default {
  components: {
    RatingIcon,
  },

  props: {
    rating: {
      type: Object,
      required: true,
    },

    modelValue: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.ratingButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 6px 11px;
  width: rem(190px);
  height: rem(44px);
  text-align: center;
  cursor: pointer;
  border-radius: rem(24px);

  @for $i from 1 through 10 {
    &--rate#{$i} {
      background: map-get($rating-colors, $i);
    }
  }
}

.ratingButton__icon {
  margin: 0 rem(4px) 0 0;
  width: rem(26px);
  height: rem(26px);
  transform: scale(1);
  transition: transform 0.35s;

  .ratingButton:hover & {
    transform: scale(1.3);
  }
}

.ratingButton__label {
  font-size: rem(16px);
  transform: translateX(0);
  transition: transform 0.35s;

  .ratingButton:hover & {
    transform: translateX(4px);
  }
}

.ratingButton__hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: rem(24px);
  background: rgba($color-white, 0.075);
  transition: width 0.35s;

  .ratingButton:hover & {
    width: 100%;
  }
}
</style>
