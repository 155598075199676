<template>
  <JWPlayer
    v-bind="$attrs"
    ref="jw"
    class="video-player"
    v-bind:file="file"
    v-bind:sources="sources"
    v-bind:image="placeholder"
    v-bind:autostart="autoplay"
    v-bind:position="position"
    v-on:ready="ready"
    v-on:play="started"
    v-on:complete="completed"
    v-on:time="progress"
    v-on:cast="cast"
  />
</template>

<script>
import JWPlayer from '@/components/JWPlayer';

export default {
  inheritAttrs: false,

  components: {
    JWPlayer,
  },

  props: {
    src: {
      type: [String, Array],
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    playbackPosition: {
      type: [String, Number],
      default: null,
    },

    trackLength: {
      type: [String, Number],
      default: null,
    },

    trackProgram: {
      type: String,
      default: null,
    },

    trackTitle: {
      type: String,
      default: null,
    },

    trackPeriod: {
      type: [String, Number],
      default: null,
    },

    trackGoal: {
      type: String,
      default: null,
    },

    trackIntensity: {
      type: String,
      default: null,
    },

    trackId: {
      type: [String, Number],
      default: null,
    },
  },

  computed: {
    file() {
      if (typeof this.src === 'string') {
        return this.src;
      }

      return null;
    },

    sources() {
      if (Array.isArray(this.src)) {
        return this.src;
      }

      return null;
    },

    attrs() {
      const attrs = { ...this.$attrs };

      delete attrs.src;
      delete attrs.placeholder;
      delete attrs.autoplay;

      return attrs;
    },

    position() {
      // if playbackPosition > 90% set the video back to 90%. Otherwise the user might end up in an infinite loop where the workout isn't finished
      const percentage = (100 / parseInt(this.trackLength)) * parseInt(this.playbackPosition);

      if (percentage > 90) {
        const playAmount = (this.trackLength / 100) * 90;

        return playAmount;
      }

      return this.playbackPosition;
    }
  },

  beforeUnmount() {
    if (this.completedAt === false && this.$refs.jw && this.$refs.jw.player) {
      const current = this.$refs.jw.player.getCurrentTime();
      const duration = this.$refs.jw.player.getDuration();
      const percentage = Math.ceil((current / duration) * 100);

      this.$store.commit('track/stop', {
        ...this.trackingData(),
        'Stopped at': `${percentage}%`,
      });
    }
  },

  methods: {
    ready() {
      this.startedAt = false;
      this.completedAt = false;
      this.percentViewed = 0;
    },

    started() {
      if (!this.startedAt) {
        this.startedAt = Date.now();
        this.$store.commit('track/play', this.trackingData());
      }
    },

    progress({ currentTime, duration }) {
      const percentage = (currentTime / duration) * 100;

      if (this.percentViewed > percentage) {
        return; // skip rewind
      }

      for (const target of [25, 50, 75]) {
        if (percentage >= target && this.percentViewed < target) {
          const data = this.trackingData();

          data.Percentage = `${target}%`;
          this.$store.commit('track/playing', data);
        }
      }

      this.percentViewed = percentage;
    },

    /**
     * Augment the complete event with extra properties.
     */
    completed(event) {
      // Check of the user hasn't skipped through the video
      this.completedAt = Date.now();

      event.duration = this.$refs.jw.player.getDuration();

      const elapsed = (this.completedAt - this.startedAt) / 1000;
      const remaining = event.duration - elapsed;

      event.watched = remaining < 600; // Didn't skip more than 10 minutes?

      if (!event.watched) {
        //console.warn("Skipped through the video", { elapsed, remaining });
      }

      this.$store.commit('track/stop', {
        ...this.trackingData(),
        'Stopped at': '100%',
      });
    },

    trackingData() {
      const data = {
        Length: this.duration(),
      };

      for (const key of ['Program', 'Title', 'Period', 'Goal', 'Intensity', 'Id']) {
        const property = `track ${key}`;

        if (this[property] !== null) {
          data[key] = this[property];
        }
      }

      if (this.$attrs.title && !data.Title) {
        data.Title = this.$attrs.title;
      }

      return data;
    },

    duration() {
      const hasPlayer = this.$refs.jw && this.$refs.jw.player;
      const duration = hasPlayer ? this.$refs.jw.player.getDuration() : 0;
      const minutes = Math.floor(duration / 60);
      let seconds = Math.floor(duration % 60).toString();

      if (seconds.length === 1) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds} min`;
    },

    cast(event) {
      this.$emit('casting', event.active);
    },

    pause() {
      if (this.$refs.jw && this.$refs.jw.player) {
        this.$refs.jw.player.pause();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.video-player {
  position: relative;
  padding: 56.25% 0 0;
  width: 100%;
  min-height: 100px;
  background: linear-gradient(
    -45deg,
    rgba(243, 246, 249, 1) 0%,
    rgba(243, 246, 249, 1) 28%,
    #eaf0f6 35%,
    rgba(243, 246, 249, 1) 42%,
    rgba(243, 246, 249, 1) 100%
  );
  background-size: 400% 400%;
  border-radius: $border-radius;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  > div,
  div.jw-error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
