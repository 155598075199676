<template>
  <div class="workoutIntro">
    <picture
        v-bind:class="{
        'workoutIntro__picture': true,
        'workoutIntro__picture--clickable': isEligibleToDoWorkout,
      }"
        v-on:click="onImageClick"
    >
      <img
          v-bind:src="workout.image"
          v-bind:class="{
          workoutIntro__image: true,
          'workoutIntro__image--locked': isLocked,
        }"
      />

      <div
          v-if="champ"
          class="workoutIntro__reminder"
      >
        <div class="workoutIntro__reminderOverlay" />
        <span class="workoutIntro__reminderHeader">
            {{ $t('workoutModalIntro.reminderHeader') }}
        </span>
        <span class="workoutIntro__reminderBody">
            {{ $t('workoutModalIntro.reminderBody') }}
        </span>
      </div>

      <font-awesome-icon
          v-if="isLocked"
          class="workoutIntro__lockedIcon"
          v-bind:icon="['fas', 'lock']"
      />

      <font-awesome-icon
          v-if="isEligibleToDoWorkout"
          class="workoutIntro__playIcon"
          v-bind:icon="['fas', 'play']"
      />
    </picture>

    <div class="workoutIntro__content">
      <div class="workoutIntro__contentLeft">
        <div class="workoutIntro__title">
          {{ workout.name }}
        </div>

        <div class="workoutIntro__buttonWrapper">
          <base-button
              v-bind:modifiers="ctaProps.modifiers"
              v-bind:disabled="ctaProps.disabled"
              class="workoutIntro__cta"
              v-on:click="ctaProps.action"
          >
            {{ ctaProps.label }}
          </base-button>

          <!--          <base-button-->
          <!--              v-bind:modifiers="ctaProps.modifiers"-->
          <!--              v-bind:disabled="ctaProps.disabled"-->
          <!--              class="workoutIntro__cta"-->
          <!--              v-on:click="ctaProps.tracker"-->
          <!--          >-->
          <!--            {{ $t('workoutModalIntro.startTrackerButtonLabel') }}-->
          <!--          </base-button>-->
        </div>
      </div>

      <div class="workoutIntro__contentRight">
        <div class="workoutIntro__details">
          <div class="workoutIntro__detailsLeft">
            <font-awesome-icon
                v-bind:icon="['fas', 'clock']"
                class="workoutIntro__icon"
            />

            {{ $t('workoutModalIntro.durationLabel', {duration}) }}
          </div>

          <div class="workoutIntro__detailsRight">
            <font-awesome-icon
                v-bind:icon="['fas', 'signal-bars']"
                class="workoutIntro__icon"
            />

            {{ intensity }}

            <span
                class="workoutIntro__intensityLink"
                v-on:click="$emit('page', 'LEVEL')"
            >
              {{ $t('workoutModalIntro.adjustLevelLinkLabel') }}
            </span>
          </div>
        </div>

        <p
            v-html-safe="workout.description"
            class="workoutIntro__body"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import BaseButton from '@/components/BaseButton';
import capitalize from '@/filters/capitalize';

export default {
  components: {
    BaseButton,
  },

  filters: {
    capitalize,
  },

  props: {
    workout: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod']),
    ...mapState('experimental', ['champ']),

    duration() {
      if (!this.workout.duration) {
        return '';
      }

      return Math.ceil(this.workout.duration / 60);
    },

    intensity() {
      const {intensities} = this.currentPeriod;
      const {active_intensity} = this.currentProgram;
      const intensity = intensities.find(({intensity}) => intensity === active_intensity);

      if (intensity) {
        return capitalize(intensity.name);
      }

      return '-';
    },

    isLocked() {
      return this.workout.status === 'locked';
    },

    isEligibleToDoWorkout() {
      const {status, can_rate} = this.workout;
      const shouldRate = status === 'done' && can_rate;

      return !this.isLocked && !shouldRate;
    },

    ctaProps() {
      const {status, feedback, can_rate, video_position} = this.workout;
      const hasVideoPosition = !!video_position;

      switch (true) {
        case this.isLocked:
          return {
            modifiers: 'primary block',
            label: this.$t('workoutModalIntro.lockedWorkoutButtonLabel'),
            disabled: true,
            action: () => {
            },
            tracker: () => {
            },
          };

        case status === 'done' && feedback > 0:
          return {
            modifiers: 'primary block',
            label: this.$t('workoutModalIntro.restartWorkoutButtonLabel'),
            action: () => this.$emit('page', 'VIDEO'),
            tracker: () => {
            },
          };

        case status === 'done' && can_rate:
          return {
            modifiers: 'primary block',
            label: this.$t('workoutModalIntro.rateWorkoutButtonLabel'),
            action: () => this.$emit('page', 'RATING'),
            tracker: () => {
            },
          };

        case hasVideoPosition: {
          const formattedTime = this.formatSeconds(video_position);

          return {
            modifiers: 'primary block',
            label: this.$t('workoutModalIntro.continueWorkoutButtonLabel', {
              time: formattedTime
            }),
            action: () => this.$emit('page', 'VIDEO'),
            tracker: () => {
            },
          };
        }

        default:
          return {
            modifiers: 'primary block',
            label: this.$t('workoutModalIntro.startWorkoutButtonLabel'),
            action: () => this.$emit('page', 'VIDEO'),
            tracker: async () => {
              await this.trackWorkout({
                workoutId: this.workout.id,
              });
            },
          };
      }
    }
  },

  methods: {
    ...mapActions({trackWorkout: 'workout/track'}),

    formatSeconds(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2);
      const minutes = (seconds % 3600) / 60;

      return [minutes, seconds % 60].map(format).join(':');
    },

    onImageClick() {
      if (this.isEligibleToDoWorkout) {
        this.$emit('page', 'VIDEO');
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.workoutIntro__body {
  p {
    margin: 0 0 1rem 0;
  }

  a {
    color: $color-text-link;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutIntro {
  padding: 0 0 rem(96px) 0;

  @include desktop {
    padding: 0;
  }
}

.workoutIntro__content {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
}

.workoutIntro__contentLeft {
  flex: 1 1 auto;
  padding: 0 rem(26px) 0 0;
}

.workoutIntro__contentRight {
  flex: 0 1 auto;
  max-width: 390px;
  padding: rem(20px) rem(20px) rem(24px) rem(20px);
  border-radius: 12px;
  background: $color-beige;
}

.workoutIntro__title {
  @include heading-2;
  margin: 0 0 rem(20px) 0;
}

.workoutIntro__details {
  display: flex;
  align-items: center;
  margin: 0 0 rem(16px) 0;
}

.workoutIntro__detailsLeft {
  margin: 0 rem(20px) 0 0;
}

.workoutIntro__icon {
  margin: 0 rem(6px) 0 0;
  color: $color-yellow;
  font-size: 18px;
}

.workoutIntro__intensityLink {
  @include small;
  margin: 0 0 0 rem(8px);
  display: inline-block;
  color: $color-text-link;
  text-decoration: underline;
  cursor: pointer;
}

.workoutIntro__picture {
  display: block;
  position: relative;
  margin: 0 rem(-16px) rem(32px) rem(-16px);

  &--clickable {
    cursor: pointer;
  }

  @include desktop {
    margin: rem(-30px);
    margin-bottom: rem(24px);
  }
}

.workoutIntro__lockedIcon,
.workoutIntro__playIcon {
  position: absolute;
  top: calc(50% - 26px);
  left: calc(50% - 26px);
  color: $color-white;
  font-size: 52px;
}

.workoutIntro__image {
  display: block;
  width: 100%;

  &--locked {
    opacity: 0.4;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    filter: brightness(110%);
  }
  50% {
    filter: brightness(100%);
  }
}

.workoutIntro__reminder {
  position: absolute;
  width: 220px;
  height: 208px;
  top: 20px;
  left: 20px;
  transform: rotateY(0deg) rotate(-5deg);
  text-align: center;
  background: transparent repeat-x url('~@/assets/integrations/champ/reminder.png');
  background-size: cover;
  transition: all .5s ease-in-out;
  animation: fadeInOut 3s infinite ease-in-out;

  .workoutIntro__reminderHeader {
    display: block;
    color: $color-green--dark;
    font-size: 30px;
    font-weight: bold;
    margin-top: 60px;
  }

  .workoutIntro__reminderBody {
    display: block;
    color: $color-black;
    font-size: 22px;
    font-weight: bold;
    margin-top: 45px;
  }
}

.workoutIntro__buttonWrapper {
  @include mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: rem(16px);
    border-top: 1px solid rgba(#000, 0.05);
    background: $color-white
  }
}

.workoutIntro__cta {
  display: inline-flex;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  @include mobile {
    width: 100%;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}
</style>
