<template>
  <div class="programLevel">
    <div class="programLevel__inner">
      <base-heading
        type="2"
        modifiers="centerDesktop"
      >
        {{ $t('programLevelModal.title') }}
      </base-heading>

      <p class="programLevel__body">
        {{ $t('programLevelModal.body') }}
      </p>

      <label
        v-for="(intensity, index) in currentPeriod.intensities"
        v-bind:key="index"
        v-bind:class="{
          'programLevel__radioButton': true,
          'programLevel__radioButton--disabled': !intensity.intensity,
        }"
      >
        <input
          v-bind:value="intensity.intensity"
          v-bind:disabled="!intensity.intensity"
          v-model="level"
          type="radio"
        />

        <span class="programLevel__radioButton__content">
          <div class="programLevel__radioButton__title">
            {{ capitalizeIntensity(intensity.name) }}
          </div>

          <div class="programLevel__radioButton__body">
            {{ intensity.description }}
          </div>
        </span>
      </label>
    </div>

    <div class="programLevel__buttons">
      <base-button
        modifiers="secondary"
        class="programLevel__button"
        v-on:click="$emit('cancelled')"
      >
        {{ $t('programLevelModal.cancelButtonLabel') }}
      </base-button>

      <base-button
        modifiers="primary"
        class="programLevel__button"
        v-bind:is-loading="isSaving"
        v-on:click="saveLevel"
      >
        {{ $t('programLevelModal.saveButtonLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import capitalize from '@/filters/capitalize';

export default {
  components: {
    BaseButton,
    BaseHeading,
  },

  data() {
    return {
      isSaving: false,
      level: null,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod']),
  },

  created() {
    this.level = this.currentProgram.active_intensity;
  },

  methods: {
    ...mapActions({
      updateIntensity: 'program/updateIntensity',
    }),

    capitalizeIntensity(intensityName) {
      return capitalize(intensityName);
    },

    async saveLevel() {
      this.isSaving = true;

      try {
        await this.updateIntensity({
          programId: this.currentProgram.id,
          intensity: this.level,
        });
      } finally {
        this.isSaving = false;
        this.$emit('confirmed');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programLevel__inner {
  @include desktop {
    margin: 0 0 3.5rem 0;
    padding: 0 2.5rem;
  }
}

.programLevel__body {
  @include paragraph;
  margin: 0 0 rem(20px) 0;

  @include desktop {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.programLevel__radioButton {
  display: flex;
  align-items: baseline;
  margin: 0 0 1.5rem 0;

  &--disabled {
    opacity: 0.5;
  }
}

.programLevel__radioButton__content {
  margin: 0 0 0 0.75rem;
}

.programLevel__radioButton__title {
  @include paragraph;
  margin: 0 0 0.3125rem 0;
}

.programLevel__radioButton__body {
  @include label;
  color: $color-grey;
}

.programLevel__buttons {
  text-align: center;
}

.programLevel__button {
  margin: 0 rem(16px) 0 0;

  &:last-child {
    margin: 0;
  }
}
</style>
