<template>
  <div
    v-bind:class="{
      'skeletonLoader': true,
      [`skeletonLoader--${type}`]: true,
      'skeletonLoader--rounded': rounded,
    }"
    v-bind:style="cssStyle"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: [String],
      default: '100%',
    },

    height: {
      type: [String],
      default: '20px',
    },

    type: {
      type: String,
      default: 'rect',
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    radius: {
      type: [Number, String],
      default: 10,
    },
  },

  computed: {
    cssStyle() {
      return {
        '--skeleton-loader-border-radius': `${this.radius}px`,
        'width': this.width,
        'height': this.height,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.skeletonLoader {
  background: linear-gradient(
    -45deg,
    var(--skeleton-loader-bg-color) 0%,
    var(--skeleton-loader-bg-color) 27%,
    var(--skeleton-loader-bg-color-mid) 35%,
    var(--skeleton-loader-bg-color) 43%,
    var(--skeleton-loader-bg-color) 100%
  );
  background-size: 400% 400%;
  animation-duration: 2.5s;
  animation-name: skeletonLoaderAnimation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &--circle {
    border-radius: 50%;
  }

  &--rounded {
    border-radius: var(--skeleton-loader-border-radius);
  }
}

@keyframes skeletonLoaderAnimation {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 120% 50%;
  }
}
</style>