<!--
  Don't use this component directly use the <VideoPlayer /> component.
  This component is only responsible for exposing the JWPlayer as a Vue Component.
-->
<template>
  <div />
</template>

<script>
import { captureMessage } from '@sentry/browser';

let scriptPromise;

export default {
  props: {
    // https://developer.jwplayer.com/jw-player/docs/javascript-api-reference/
    file: {
      type: String,
      default: null,
    },

    sources: {
      type: Array,
      default: null,
    },

    image: {
      type: String,
      default: '',
    },

    autostart: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },

    tracks: {
      type: Array,
      default: () => [],
    },

    position: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      cast: {},
    };
  },

  computed: {
    srcHash() {
      return `${JSON.stringify(this.file)}_${JSON.stringify(this.sources)}`;
    },
  },

  watch: {
    srcHash: {
      handler() {
        this.loadVideo();
      },
      immediate: true,
    },
  },

  unmounted() {
    if (this.player) {
      this.player.pause();
      this.player.remove();
    }
  },

  methods: {
    loadScript() {
      if (!scriptPromise) {
        scriptPromise = new Promise((resolve, reject) => {
          const s = document.createElement('script');
          s.src = 'https://cdn.jwplayer.com/libraries/o64GVjX6.js';
          s.async = true;
          s.onload = resolve;
          s.onerror = reject;
          s.onabort = reject;
          document.head.appendChild(s);
        });
      }

      return scriptPromise;
    },

    async loadVideo() {
      if (!this.loading) {
        this.loading = true;

        await this.loadScript();

        this.div = document.createElement('div');
        this.$el.appendChild(this.div);
        this.player = window.jwplayer(this.div);
        const config = {};

        for (const key of ['file', 'sources', 'image', 'title', 'autostart', 'tracks', 'cast']) {
          if (this[key]) {
            config[key] = this[key];
          }
        }

        this.player.setup(config);
        this.player.on('all', this.$emit);

        window.player = this.player;

        this.player.on('setupError', ({ code, message, type }) => {
          captureMessage(`JWPlayer ${type} - ${code} - message: ${message}`);
        });

        this.player.on('error', ({ code, message, type }) => {
          captureMessage(`JWPlayer ${type} - ${code} - message: ${message}`);
        });

        if (this.position) {
          const position = this.position;
          this.player.seek(position);
        }
      } else if (this.player) {
        this.player.remove();
        this.player = null;
        this.$el.removeChild(this.div);
        this.loading = false;

        this.loadVideo();
      }
    },
  },
};
</script>
