<template>
  <div>
    <skeleton-loader-card
      v-if="isLoading || !currentWorkout.id"
      image-height="450px"
    />

    <workout-intro
      v-else-if="page === 'INTRO'"
      v-bind:workout="currentWorkout"
      v-on:close="$emit('close')"
      v-on:page="setPage"
    />

    <program-level
      v-else-if="page === 'LEVEL'"
      v-on:cancelled="page = 'INTRO'"
      v-on:confirmed="page = 'INTRO'"
    />

    <workout-video
      v-else-if="currentWorkout.id && page === 'VIDEO'"
      ref="workoutVideo"
      v-bind:workout="currentWorkout"
      v-on:nextPage="page = 'RATING'"
      v-on:close="$emit('close')"
    />

    <workout-rating
      v-else-if="page === 'RATING'"
      v-bind:workout="currentWorkout"
      v-on:close="$emit('close')"
      v-on:page="setPage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import ProgramLevel from '@/components/program/ProgramLevel';
import WorkoutIntro from '@/components/workout/WorkoutIntro';
import WorkoutVideo from '@/components/workout/WorkoutVideo';
import WorkoutRating from '@/components/workout/WorkoutRating';
import capitalize from '@/filters/capitalize';

export default {
  components: {
    ProgramLevel,
    SkeletonLoaderCard,
    WorkoutVideo,
    WorkoutIntro,
    WorkoutRating,
  },

  props: {
    periodId: {
      type: Number,
      required: true,
    },

    workoutId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      page: this.$route.query.page || 'INTRO',
    };
  },

  computed: {
    ...mapGetters('workout', ['currentWorkout']),
  },

  watch: {
    page(page, oldPage) {
      this.toggleModalModifier(false, oldPage);
      this.toggleModalModifier(true, page);
    },
  },

  async mounted() {
    this.isLoading = true;
    this.toggleModalModifier(true, this.page);

    await this.$store.dispatch('workout/fetch', this.workoutId);

    this.isLoading = false;
  },

  unmounted() {
    this.$store.commit('workout/unsetCurrentWorkout');
    this.toggleModalModifier(false, this.page);
  },

  methods: {
    toggleModalModifier(bool, page) {
      const func = bool ? 'add' : 'remove';
      const event = `${func}Modifier`;

      this.$emit(event, `workout${capitalize(page.toLowerCase())}`);
    },

    beforeClose(trigger) {
      return new Promise(async (resolve, reject) => {
        if (this.page === 'VIDEO') {
          if (trigger === 'background') {
            reject('Background close is blocked during video playback');
            return;
          }

          this.$refs.workoutVideo.pause();

          await this.$store.dispatch('period/fetch', this.periodId);

          if (this.currentWorkout.can_rate) {
            reject('Member is eligible to rate');
            this.page = 'RATING';
            return;
          }

          this.toggleModalModifier(false, this.page);

          resolve();
          return;
        }

        resolve();
      });
    },

    setPage(page) {
      this.page = page;
    },
  },
};
</script>