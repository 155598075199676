<template>
  <label
    v-bind:for="forId"
    v-bind:class="{
      [blockName]: true,
      [modifierClasses]: true,
    }"
  >
    <slot />
  </label>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers(['block']),
  ],

  props: {
    forId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      blockName: 'formLabel',
    };
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formLabel {
  @include label;
  display: inline-block;
  margin: 0 0 rem(2px) 0;
  color: $color-text;

  &--block {
    display: block;
  }
}
</style>