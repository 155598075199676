<template>
  <div
    v-if="!isLoading"
    v-bind:class="{
      'workoutRating': true,
      [`workoutRating--${animationClass}`]: true,
    }"
  >
    <div class="workoutRating__step1">
      <div class="workoutRating__contentStep1">
        <div class="workoutRating__title workoutRating__titleStep1">
          {{ $t('workoutModalRating.titleStep1', { name: member.name }) }}
        </div>

        <div
          v-if="!isNaN(burnedCalories)"
          class="workoutRating__workoutInfo workoutRating__workoutInfo--calories"
        >
          <font-awesome-icon
            v-bind:icon="['fas', 'fire']"
            class="workoutRating__workoutInfoIcon"
          />

          {{ $t('workoutModalRating.burnedCaloriesLabel', { calories: burnedCalories }) }}
        </div>

        <div class="workoutRating__workoutInfo workoutRating__workoutInfo--points">
          <font-awesome-icon
            v-bind:icon="['fas', 'star']"
            class="workoutRating__workoutInfoIcon"
          />

          {{ $t('workoutModalRating.earnedPointsLabel', { points: 500 }) }}
        </div>
      </div>

      <img
        v-bind:src="illustration"
        class="workoutRating__image"
      />
    </div>

    <div class="workoutRating__step2">
      <div class="workoutRating__content">
        <div class="workoutRating__title">
          {{ $t('workoutModalRating.title') }}
        </div>

        <p class="workoutRating__body">
          {{ $t('workoutModalRating.body') }}
        </p>

        <div class="workoutRating__ratingNote">
          <div
            v-if="!showNoteInput"
            class="workoutRating__ratingNote__link"
            v-on:click="showNoteInput = true"
          >
            {{ $t('workoutModalRating.addRatingNoteLabel') }}
          </div>

          <base-form-textarea
            v-if="showNoteInput"
            v-model="note"
            class="workoutRating__ratingNote__input"
            v-bind:placeholder="$t('workoutModalRating.addRatingNotePlaceholder')"
          />

          <p
            v-if="ratingError"
            class="workoutRating__ratingError"
          >
            {{ ratingError }}
          </p>
        </div>
      </div>

      <div class="workoutRating__ratingItems">
        <workout-rating-item
          v-for="(rating, index) in ratings"
          v-bind:key="index"
          v-model="selectedRating"
          v-bind:rating="rating"
          v-on:click="onSubmitClick"
          class="workoutRating__ratingItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import WorkoutRatingItem from '@/components/workout/WorkoutRatingItem';
import BaseFormTextarea from '@/components/forms/BaseFormTextarea';
import workoutRatings from '@/data/workoutRatings';
import calculateCalories from '@/utils/calculateCalories';
import getRandomInt from '@/utils/getRandomInt';
import confetti from '@/mixins/confetti';

export default {
  components: {
    WorkoutRatingItem,
    BaseFormTextarea,
  },

  mixins: [
    confetti,
  ],

  props: {
    workout: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      ratings: workoutRatings,
      note: '',
      selectedRating: {},
      showNoteInput: false,
      isSavingRating: false,
      ratingError: '',
      animationClass: 'step1In',
      illustrations: [
        { src: 'illustration-f-1.svg', gender: 'f' },
        { src: 'illustration-f-2.svg', gender: 'f' },
        { src: 'illustration-m-1.svg', gender: 'm' },
      ],
    };
  },

  computed: {
    ...mapState('member', ['member', 'body', 'profile']),
    ...mapGetters('period', ['currentPeriod']),

    illustration() {
      const { gender } = this.profile;
      let illustrations = [...this.illustrations];

      if (gender && gender !== '9' && gender !== '0') {
        illustrations = this.illustrations.filter(illustration => illustration.gender === gender);
      }

      const illustrationIndex = getRandomInt(0, illustrations.length - 1);
      const illustration = illustrations[illustrationIndex];

      return require(`@/assets/workout/${illustration.src}`);
    },

    burnedCalories() {
      const { history } = this.body;
      const { duration , calories } = this.workout;
      const historyLength = history.length;
      const weight = historyLength ? history[historyLength - 1].weight : null;

      return calculateCalories(weight, duration / 60, calories);
    },
  },

  async created() {
    const today = new Date();
    const fetchBodyData = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
    };

    await this.fetchBody(fetchBodyData);
    await this.fetchProfile();

    this.isLoading = false;
  },

  mounted() {
    setTimeout(() => {
      this.animationClass = 'step1Out';

      setTimeout(() => {
        this.animationClass = 'step2In';
      }, 350);
    }, 3000);

    this.giveMeConfetti(1500);
  },

  methods: {
    ...mapActions({
      fetchMember: 'member/fetch',
      fetchProfile: 'member/fetchProfile',
      fetchBody: 'member/fetchBody',
    }),

    ...mapActions({ rateWorkout: 'workout/rate' }),
    ...mapActions({ fetchPeriod: 'period/fetch' }),

    async onSubmitClick() {
      if (!this.isSavingRating) {
        this.isSavingRating = true;

        try {
          await this.rateWorkout({
            workoutId: this.workout.id,
            rating: this.selectedRating.value,
            note: this.note,
          });

          this.$emit('close');
          this.fetchPeriod(this.currentPeriod.id);
          this.fetchMember();
        } catch (error) {
          this.ratingError = this.$t('workoutModalRating.oops', {
            message: error.message,
          });
        } finally {
          this.isSavingRating = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutRating {
  position: relative;
}

.workoutRating__step1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include desktop {
    display: flex;
    align-items: center;
  }

  .workoutRating--step1Out & {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s;
  }

  .workoutRating--step2In & {
    display: none;
  }
}

.workoutRating__contentStep1 {
  margin: 0 0 rem(10px) 0;
  text-align: center;

  @include desktop {
    position: relative;
    margin: 0;
    text-align: left;
    z-index: 1;
  }
}

.workoutRating__workoutInfo {
  margin: 0 0 rem(6px) 0;

  &:last-child {
    margin: 0;
  }

  .workoutRating--step1In & {
    opacity: 0;
    transform: translateX(100px);
    animation: 0.5s ease-out 0s forwards slideInFromRight;

    &--calories {
      animation-delay: 1s;
    }

    &--points {
      animation-delay: 1.5s;
    }
  }
}

@keyframes slideInFromRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.workoutRating__workoutInfoIcon {
  margin: 0 rem(6px) 0 0;
  width: 23px;
  font-size: rem(20px);
  color: $color-yellow;
}

.workoutRating__image {
  display: block;
  margin: 100px auto 0 auto;
  max-width: 100%;
  height: auto;

  @include desktop {
    position: absolute;
    top: 50%;
    right: 100px;
    margin-top: -150px;
    margin-right: rem(-60px);
  }
}

.workoutRating__step2 {
  opacity: 0;
  visibility: hidden;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .workoutRating--step2In & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.35s, visibility 0.35s;
  }
}

.workoutRating__content {
  margin: 0 0 rem(38px) 0;
  text-align: center;

  @include desktop {
    flex: 1 1 auto;
    margin: 0;
    text-align: left;
  }
}

.workoutRating__title {
  @include heading-3;
  margin: 0 0 rem(6px) 0;
  text-align: center;

  &.workoutRating__titleStep1 {
    margin: 0 0 rem(20px) 0;
    opacity: 0;
    transform: translateX(100px);
    animation: 0.5s ease-out 0.25s forwards slideInFromRight;
  }

  @include desktop {
    @include heading-1;
    margin: 0 0 rem(22px) 0;
    text-align: left;
  }
}

.workoutRating__body {
  margin: 0 0 rem(22px) 0;

  @include mobile {
    display: none;
  }
}

.workoutRating__ratingItems {
  @include desktop {
    flex: 0 0 auto;
    margin: 0 0 0 rem(100px);
  }
}

.workoutRating__ratingItem {
  margin: 0 auto rem(6px) auto;

  &:last-child {
    margin: 0 auto;
  }

  .workoutRating--step2In & {
    opacity: 0;
    transform: translateX(100px);
    animation: 0.5s ease-out 0s forwards slideInFromRight;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 75}ms;
      }
    }
  }
}

.workoutRating__ratingNote {
  margin: 1rem 0 0 0;
}

.workoutRating__ratingNote__link {
  @include label;
  text-decoration: underline;
  color: $color-grey;
  cursor: pointer;
}

.workoutRating__ratingNote__input {
  margin: 0 0 rem(12px) 0;
  border-radius: 6px;
  border-color: $color-beige--dark;

  @include desktop {
    max-width: 263px;
  }
}

.workoutRating__ratingError {
  margin: rem(16px) 0 0 0;
  padding: 8px;
  text-align: center;
  background: $color-error-light;
  color: $color-error-dark;
  border-radius: $border-radius;
}
</style>
