<template>
  <div class="workoutVideoReportTool">
    <div class="workoutVideoReportTool__window">
      <div
        v-if="!feedbackSent"
        class="workoutVideoReportTool__form"
      >
        <div class="workoutVideoReportTool__titleWrapper">
          <div class="workoutVideoReportTool__title">
            {{ $t('workoutVideoreportTool.title') }}
          </div>

          <button
            class="workoutVideoReportTool__close"
            v-on:click="$emit('close')"
          >
            <font-awesome-icon icon="xmark" />
          </button>
        </div>

        <ul class="workoutVideoReportTool__list">
          <li
            v-for="(videoIssueOption, index) in videoIssueOptions"
            v-bind:key="index"
            v-bind:class="{
              'workoutVideoReportTool__listItem': true,
              'workoutVideoReportTool__listItem--active': videoIssue === videoIssueOption,
            }"
            v-on:click="videoIssue = videoIssueOption"
          >
            {{ videoIssueOption.label }}

            <base-form-element
              v-if="videoIssue === videoIssueOption"
              v-show="videoIssue === videoIssueOptions[videoIssueOptions.length - 1]"
              class="workoutVideoReportTool__listItemFormElement"
            >
              <base-form-label for-id="remarks">
                {{ $t('workoutVideoreportTool.remarksLabel') }}
              </base-form-label>

              <base-form-textarea
                id="remarks"
                v-model="remarks"
              />
            </base-form-element>
          </li>
        </ul>

        <base-button
          modifiers="primary small"
          v-bind:disabled="!videoIssue"
          v-on:click="onSubmit"
        >
          {{ $t('workoutVideoreportTool.sendButtonLabel') }}
        </base-button>
      </div>

      <div
        v-if="feedbackSent"
        class="workoutVideoReportTool__sent"
      >
        {{ $t('workoutVideoreportTool.formSentBody') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseButton from '@/components/BaseButton';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormTextarea from '@/components/forms/BaseFormTextarea';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    BaseButton,
    BaseFormElement,
    BaseFormLabel,
    BaseFormTextarea,
  },

  props: {
    workout: {
      type: Object,
      required: true,
    },

    videoStreams: {
      type: Array,
      required: true,
    },

    videoError: {
      type: [Object, null],
    },
  },

  data() {
    return {
      videoIssueOptions: [
        { label: this.$t('workoutVideoreportTool.videoIssueOption1Label'), value: 'Workout is not loading' },
        { label: this.$t('workoutVideoreportTool.videoIssueOption2Label'), value: 'Workout stops in the middle of an exercise' },
        { label: this.$t('workoutVideoreportTool.videoIssueOption3Label'), value: 'Audio is missing in the workout' },
        { label: this.$t('workoutVideoreportTool.videoIssueOption4Label'), value: 'I cannot cast the video' },
        { label: this.$t('workoutVideoreportTool.videoIssueOption5Label'), value: 'Other' },
      ],
      videoIssue: null,
      remarks: '',
      feedbackSent: false,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod']),
  },

  methods: {
    onSubmit() {
      const { intensities } = this.currentPeriod;
      const { active_intensity } = this.currentProgram;
      const intensity = intensities.find(({ intensity }) => intensity === active_intensity);
      const intensityName = intensity ? intensity.name : '-';

      this.trackEvent('broken_video_report', {
        reported_issue: this.videoIssue.value,
        reported_issue_remarks: this.remarks,
        workout_id: this.workout.id,
        workout_intensity: intensityName,
        video_url: this.videoStreams[0].file,
        video_stream_type: this.videoStreams[0].type,
        video_duration: this.workout.duration,
        video_position: this.workout.video_position,
        video_error: this.videoError,
      });

      this.feedbackSent = true;

      setTimeout(() => {
        this.$emit('close');
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutVideoReportTool {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.75);
  z-index: 5;
}

.workoutVideoReportTool__window {
  position: relative;
  padding: rem(20px) rem(16px);
  border-radius: 10px;
  background: $color-black;
}

.workoutVideoReportTool__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 rem(16px) 0;
  padding: 0 rem(10px);
}

.workoutVideoReportTool__title {
  @include paragraph--bold;
  color: $color-white;
}

.workoutVideoReportTool__close {
  font-size: rem(20px);
  color: $color-white;
}

.workoutVideoReportTool__list {
  margin: 0 0 rem(20px) 0;
  padding: 0;
  list-style: none;
}

.workoutVideoReportTool__listItem {
  @include label;
  margin: 0 0 rem(10px) 0;
  padding: rem(10px);
  border-radius: 10px;
  cursor: pointer;
  color: $color-white;
  transition: background 0.35s;

  &:hover,
  &--active {
    background: $color-grey;
  }

  &:last-child {
    margin: 0;
  }
}

.workoutVideoReportTool__listItemFormElement {
  margin-top: rem(10px) !important;
}

.workoutVideoReportTool__sent {
  color: $color-white;
}
</style>