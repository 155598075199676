<template>
  <textarea
    v-bind="$attrs"
    class="formInput"
    v-on:input="$emit('update:modelValue', $event.target.value)"
  ></textarea>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formInput {
  @include paragraph;
  padding: rem(8px);
  width: 100%;
  background: $color-white;
  border: 1px solid $color-beige--dark;
  border-radius: rem(6px);
  color: $color-text;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    outline-color: $color-blue;
  }
}
</style>